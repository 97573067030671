import React from "react";
import { graphql, navigate } from "gatsby";
import Container from "@material-ui/core/Container";
import Layout from "../../../components/layout";
import ModuleLoader from "../../../modules";
import Icon from "../../../components/icons";
import * as styles from "./styles.module.scss";

class Entry extends React.Component {
  render() {
    const entry = this.props.data.strapiEntries;
    return (
      <Layout metatags={entry.metatags}>
        <ModuleLoader
          config={{
            skin: "default",
            limitHeight: true,
            maxHeight: 300,
            maxHeightUnit: "px",
            opacity: true,
            opacityColor: "#000000",
            opacityLevel: 0.12,
            title: entry.shortTitle,
            textSize: "medium",
            blockSettings: {
              backgroundVideoUrl: false,
              backgroundImage: entry.largeImage,
              backgroundImagePosition: "center center",
              clipPath: false,
              visible: true,
            },
          }}
          name={"header"}
        />

        <ModuleLoader
          config={{
            skin: "default",
            color: "transparent",
            topSpacing: 75,
            topSpacingUnit: "px",
            blockSettings: {
              visible: true,
            },
          }}
          name={"divider"}
        />

        <Container component={"div"} maxWidth={"lg"}>
          <button onClick={() => navigate(-1)} className={styles.backButton}>
            <Icon icon="ArrowLeft" size="Small" />
            Back
          </button>
        </Container>

        <ModuleLoader
          config={{
            skin: "default",
            title: entry.title,
            subtitle: "",
            description: entry.content,
            alignment: "left",
            contentSettings: {
              titleColor: "#001D28",
              descriptionColor: "#5C5C5F",
            },
            blockSettings: {
              visible: true,
            },
          }}
          name={"text-block"}
        />

        <ModuleLoader
          config={{
            skin: "default",
            color: "transparent",
            topSpacing: 75,
            topSpacingUnit: "px",
            blockSettings: {
              visible: true,
            },
          }}
          name={"divider"}
        />
      </Layout>
    );
  }
}

export const query = graphql`
  query ($id: String!) {
    strapiEntries(id: { eq: $id }) {
      id
      name
      slug
      metatags {
        title
        description
        keywords
        robots
      }
      shortTitle
      title
      excerpt
      content
      image {
        formats {
          small {
            url
          }
        }
      }
      largeImage {
        url
        caption
        formats {
          medium {
            url
          }
          large {
            url
          }
        }
      }
    }
  }
`;

export default Entry;
